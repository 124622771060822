<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('erp.lang_mealType')"
                :subheading="$t('erp.lang_editMealTypeName')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <meal-type-edit-component/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import MealTypeEditComponent from "../../../../components/erp/settings/mealType/MealTypeEditComponent";
export default {
  name: "AllergensEdit",
  components: {MealTypeEditComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  }),
}
</script>

<style scoped>

</style>